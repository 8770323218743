<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="devices-page">

    <div class="d-flex">
      <div class="ui-spacer"></div>
    </div>

    <b-card no-body class="mb-0">
      <div class="card-header">
        <h4 class="card-title">Targets</h4>
        <section class="d-flex align-items-center justify-content-end">
          <b-button v-if="$can('Create', 'Target')" @click="$bvModal.show('modal-add-target')" class="btn-success pull-right">Add Target</b-button>
        </section>
      </div>
      <section>
        <b-table
          class="data-table"
          hover
          :items="targets"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(target)="data">
            <router-link :to="{ name: 'target-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap text-underline">
            {{data.item.target }}
            </router-link>
          </template>
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>
          <template #cell(is_device_secure)="data">
            {{data.item.is_device_secure | boolToYes}}
          </template>
          <template #cell(actions)="data">
            <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Target')"
                                      @click="showTargetModal(data.item, 'modal-update-target')">
                Update Target
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="$router.push({ name: 'target-view', params: { uid: data.item.uid } })" class="full-nested-btn" v-if="$can('Update', 'Target')">
                  Products
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Target')"
                                      @click="showScansModal(data.item, 'modal-target-scans')">
                Vulnerability Scan Settings
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="$router.push({ name: 'target-scans', params: { uid: data.item.uid } })" class="full-nested-btn" v-if="$can('Update', 'Target')">
                  Scheduled Scans
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Target')"
                                      @click="showTargetModal(data.item, 'modal-delete-target')">
                Delete Target
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreTargets" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
    <b-modal id="modal-add-target" title="Add new target" hide-footer>
      <add-target @refresh="refreshTargets()" @close="closeModals()"></add-target>
    </b-modal>

    <b-modal id="modal-update-target" title="Update Target" hide-footer>
      <update-target v-if="selectedTarget" :uid="selectedTarget.uid" :target-existing="selectedTarget" @refresh="refreshTargets()" @close="closeModals()"></update-target>
    </b-modal>
    <b-modal id="modal-delete-target" title="Delete target" hide-footer>
      <delete-modal
        v-if="selectedTarget"
        @close="closeModals()" @delete="deleteTarget(selectedTarget)"
        :subtitle="selectedTarget.name"
        title="Are you sure you wish to delete target"></delete-modal>
    </b-modal>
    <b-modal id="modal-target-scans" title="Scan Target" hide-footer>
      <scans-target
        v-if="selectedTarget" :uid="selectedTarget.uid" :target-existing="selectedTarget" @refresh="refreshTargets()" @close="closeModals()"></scans-target>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import { BPagination } from 'bootstrap-vue'
  import DeleteModal from '../../components/modals/DeleteModal.vue';
  import TargetService from '../../services/TargetService';
  import AddTarget from './viewTarget/sections/AddTarget.vue';
  import UpdateTarget from './viewTarget/sections/UpdateTarget.vue';
  import ScansTarget from './viewTarget/sections/ScansModal.vue';

  export default {
    name: 'Targets',
    components: {
      AddTarget,
      DeleteModal,
      UpdateTarget,
      ScansTarget,
      BPagination,
    },
    props: {},
    data() {
      return {
        selectedTarget: null,
        headers: [
          {
            key: 'target',
            label: 'Target',
          },
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'ssl_check',
            label: 'SSL Check',
          },
          {
            key: 'created_at',
            label: 'Added',
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'text-right',
          },
        ],
        targets: [],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
          search: '',
        },
        total_count: 0,
      };
    },
    mounted() {
      this.getTargets();
    },
    methods: {
      refreshTargets() {
        this.closeModals();
        this.getTargets();
      },
      startNewSearch() {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.getMoreTargets(1);
        }, 500);
      },
      closeModals() {
        this.$bvModal.hide('modal-add-target');
        this.$bvModal.hide('modal-delete-target');
        this.$bvModal.hide('modal-update-target');
        this.$bvModal.hide('modal-delete-modal');
        this.$bvModal.hide('modal-target-scans');
      },
      getMoreTargets(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getTargets();
      },
      showTargetModal(target, modalName) {
        this.setSelectedTarget(target);
        this.$bvModal.show(modalName);
      },
      showScansModal(target, modalName) {
        this.setSelectedTarget(target);
        this.$bvModal.show(modalName);
      },
      setSelectedTarget(target) {
        this.selectedTarget = target;
      },
      getTargets() {
        TargetService.getTargets(this.filters).then(res => {
          this.targets = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(() => {
          this.$toast.error('Could not get targets, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      deleteTarget(target) {
        TargetService.deleteTarget(target.uid).then(() => {
          this.$toast.success(`Deleted target ${target.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshTargets();
        }).catch(() => {
          this.$toast.error('Could not delete target, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      clearFilters() {
        this.filters = {
          page: 0,
          page_size: 25,
          search: '',
        };

        this.getMoreTargets(1);
      },
    },
  };
</script>
