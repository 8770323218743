<style lang="scss">
  .update-target-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-target-modal">
    <section class="mb-2">
      <b-form-group
        label="Host Name"
        label-for="hostname"
      >
        <validation-provider
          v-slot="validationContext"
          name="Hostname"
          :rules="{ required: true }"
        >
          <b-form-input v-model="target.target"
                        required
                        :state="getValidationState(validationContext)"
                        placeholder="Hostname*"
          />
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Target Name"
        label-for="hostname"
      >
        <validation-provider
          v-slot="validationContext"
          name="Target Name"
          :rules="{ required: true }"
        >
          <b-form-input v-model="target.name"
                        required
                        :state="getValidationState(validationContext)"
                        placeholder="Target Name*"
          />
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
    </section>

    <b-form-group
      label="Notification Group"
      label-for="update-group-notification"
    >
      <validation-provider
        v-slot="validationContext"
        name="Notification Group"
      >
        <b-form-select v-model="selectedValue" name="notification_group_uid" :state="getValidationState(validationContext)" class="mb-2">
          <b-form-select-option value="" disabled>Select a group</b-form-select-option>
          <b-form-select-option :selected="selectedValue === undefined" value="">None</b-form-select-option>
          <b-form-select-option v-for="group in groups" :key="group.uid" :value="group.uid" :selected="selectedValue === group.uid">{{ group.title }}</b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateTarget()"
      >Update Target</b-button>
    </section>

  </div>
</template>

<script>
  import TargetService from '@/services/TargetService'
  import NotificationGroupsService from '@/services/NotificationGroupsService'

  export default {
    components: {
    },
    props: {
      targetExisting: {
        required: true,
        type: Object,
      },
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        target: {
          uid: '',
          name: '',
          target: '',
          notification_group_uid: '',
        },
        groups: [],
        selectedValue: '',
      };
    },
    computed() {
      this.selectedValue = this.target.notification_group_uid;
    },
    mounted() {
      this.getTarget();
      this.getNotificationGroups();
    },
    methods: {
      getTarget() {
        TargetService.getTarget(this.uid).then(res => {
          this.target.uid = res.data.uid;
          this.target.name = res.data.name;
          this.target.target = res.data.target;
          if (res.data.notification_group_uid !== null) {
            this.target.notification_group_uid = res.data.notification_group_uid;
          } else {
            this.target.notification_group_uid = undefined
          }
          if (this.target.notification_group_uid !== '') {
            this.selectedValue = this.target.notification_group_uid;
          } else {
            this.selectedValue = undefined
          }
        }).catch(() => {
          this.$toast.error('Could not get target, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          if (this.target.notification_group_uid !== null) {
            this.getGroup(this.target.notification_group_uid);
          }
          this.loading = true;
        });
      },
      getGroup(uid) {
        if (typeof (uid) !== 'undefined') {
          NotificationGroupsService.getGroup(uid)
            .then(res => {
              this.selected = [{
                title: res.data.name,
                uid: res.data.uid,
              }]
            })
            .catch(() => {
              this.$toast.error('Could not get notification group, please refresh and try again', {
                toastClassName: ['toast-std', 'warning-toast'],
              });
            })
            .finally(() => {
              this.loading = true;
            });
        }
      },
      getNotificationGroups() {
        NotificationGroupsService.getGroups().then(res => {
          this.groups = res.data.data;
          const arr = [];
          this.groups.forEach(value => {
            arr.push({ title: value.name, uid: value.uid });
          });
          this.groups = arr
        }).catch(() => {
          this.$toast.error('Could not get notification groups, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          this.loading = false;
        });
      },
      updateTarget() {
        if (typeof (this.selectedValue) !== 'undefined') {
          if (this.selectedValue !== '') {
            this.target.notification_group_uid = this.selectedValue;
          } else {
            this.target.notification_group_uid = undefined
          }
        } else {
          this.target.notification_group_uid = undefined
        }
        TargetService.updateTarget(this.target.uid, this.target).then(() => {
          this.$toast.success(`Updated target ${this.target.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not update target, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const name = this.target.name.length > 0;
        const target = this.target.target.length > 0;
        // const targetGroup = this.target.notification_group_uid.length > 0;

        return name && target;
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
