<style lang="scss">
.add-target-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="add-target-modal">
    <section class="mb-2">
      <b-form-group
        label="Host Name"
        label-for="hostname"
      >
        <validation-provider
          v-slot="validationContext"
          name="Hostname"
          :rules="{ required: true}"
        >
          <b-form-input v-model="target.target"
                        required
                        :state="getValidationState(validationContext)"
                        placeholder="Hostname*"
          />
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Target Name"
        label-for="hostname"
      >
        <validation-provider
          v-slot="validationContext"
          name="Target Name"
          :rules="{ required: true }"
        >
          <b-form-input v-model="target.name"
                        required
                        :state="getValidationState(validationContext)"
                        class="" placeholder="Target Name*"
          />
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
    </section>

    <b-form-group
      label="Notification Group"
      label-for="update-group-notification"
    >

      <validation-provider
        v-slot="validationContext"
        name="Notification Group"
        :rules="{ required: true }"
      >
        <b-form-select v-model="selected" :state="getValidationState(validationContext)" required class="mb-2">
          <b-form-select-option value="" disabled>Select a Group</b-form-select-option>
          <b-form-select-option value="">None</b-form-select-option>
          <b-form-select-option v-for="group in groups" :key="group.uid" :value="group.uid">{{ group.title }}</b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="addTarget()"
      >Add new target</b-button>
    </section>

  </div>
</template>

<script>
  import NotificationGroupsService from '@/services/NotificationGroupsService';
  import RolesService from '@/services/RolesService';
  import TargetService from '@/services/TargetService';

  export default {
    components: {
    },
    props: {},
    data() {
      return {
        showPassword: false,
        target: {
          target: '',
          name: '',
          notification_group_uid: '',
        },
        groups: [],
        selected: '',
      };
    },
    mounted() {
      this.getRoles();
      this.getNotificationGroups();
    },
    methods: {
      getNotificationGroups() {
        NotificationGroupsService.getGroups().then(res => {
          this.groups = res.data.data;
          const arr = [];
          this.groups.forEach(value => {
            arr.push({ title: value.name, uid: value.uid });
          });
          this.groups = arr
        }).catch(() => {
          this.$toast.error('Could not get notification groups, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          this.loading = false;
        });
      },
      addTarget() {
        if (typeof (this.selected) !== 'undefined') {
          if (this.selected !== '') {
            this.target.notification_group_uid = this.selected;
          } else {
            this.target.notification_group_uid = undefined;
          }
        } else {
          this.target.notification_group_uid = undefined;
        }
        TargetService.createTarget(this.target).then(() => {
          this.$toast.success(`Added target ${this.target.target} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not add target, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      checkFormValid() {
        const targetIp = this.target.target.length > 0;
        const targetName = this.target.name.length > 0;
        // const targetGroup = this.selected.length > 0;

        return targetIp && targetName // && targetGroup;
      },
      getRoles() {
        RolesService.getRoles().then(res => {
          this.roles = res.data;
        }).catch(() => {
          this.$toast.error('Could not get roles, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>
<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
