<style lang="scss">
  .update-target-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-target-modal">
    <section class="mb-2">
      <b-form-group
        label="Scan Day"
        label-for="hostname"
      >
        <validation-provider
          v-slot="validationContext"
          name="Scan Day"
          :rules="{ required: true }"
        >
          <b-form-select v-model="target.vulnerability_scan_day" name="vulnerability_scan_day" class="mb-2">
            <b-form-select-option :selected="target.vulnerability_scan_day === 'monday'" :value="'monday'">Monday</b-form-select-option>
            <b-form-select-option :selected="target.vulnerability_scan_day === 'tuesday'" :value="'tuesday'">Tuesday</b-form-select-option>
            <b-form-select-option :selected="target.vulnerability_scan_day === 'wednesday'" :value="'wednesday'">Wednesday</b-form-select-option>
            <b-form-select-option :selected="target.vulnerability_scan_day === 'thursday'" :value="'thursday'">Thursday</b-form-select-option>
            <b-form-select-option :selected="target.vulnerability_scan_day === 'friday'" :value="'friday'">Friday</b-form-select-option>
            <b-form-select-option :selected="target.vulnerability_scan_day === 'saturday'" :value="'saturday'">Saturday</b-form-select-option>
            <b-form-select-option :selected="target.vulnerability_scan_day === 'sunday'" :value="'sunday'">Sunday</b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Scan Time"
        label-for="hostname"
      >
        <validation-provider
          v-slot="validationContext"
          name="Scan Time"
          :rules="{ required: true }"
        >
          <flat-pickr
            v-model="target.vulnerability_scan_time"
            class="form-control"
            required
            :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
          />

          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
    </section>

    <b-form-group
      label="Enable Scan"
      label-for="update-group-notification"
    >
      <validation-provider
        v-slot="validationContext"
        name="Enable scan"
      >
        <b-form-select v-model="target.vulnerability_scans" name="vulnerability_scans" class="mb-2">
          <b-form-select-option :selected="target.vulnerability_scans === true" :value="true">On</b-form-select-option>
          <b-form-select-option :selected="target.vulnerability_scans === false" :value="false">Off</b-form-select-option>
        </b-form-select>
        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateTarget()"
      >Update Target</b-button>
    </section>

  </div>
</template>

<script>
  import TargetService from '@/services/TargetService'
  import flatPickr from 'vue-flatpickr-component'

  export default {
    components: {
      flatPickr,
    },
    props: {
      targetExisting: {
        required: true,
        type: Object,
      },
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        timePicker: null,
        target: {
          uid: '',
          vulnerability_scans: '',
          vulnerability_scan_time: '',
          vulnerability_scan_day: '',
        },
        groups: [],
        selectedValue: '',
      };
    },
    mounted() {
      this.getTarget();
    },
    methods: {
      getTarget() {
        TargetService.getTarget(this.uid).then(res => {
          this.target.uid = res.data.uid;
          this.target.vulnerability_scans = res.data.vulnerability_scans;
          this.target.vulnerability_scan_day = res.data.vulnerability_scan_day;
          if (res.data.vulnerability_scan_time !== null) {
            this.target.vulnerability_scan_time = res.data.vulnerability_scan_time;
          } else {
          this.target.vulnerability_scan_time = undefined
          }
        }).catch(() => {
          this.$toast.error('Could not get target, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          this.loading = true;
        });
      },
      updateTarget() {
        if (typeof (this.selectedValue) !== 'undefined') {
          if (this.selectedValue !== '') {
            this.target.notification_group_uid = this.selectedValue;
          } else {
            this.target.notification_group_uid = undefined
          }
        } else {
          this.target.notification_group_uid = undefined
        }
        TargetService.patchTarget(this.target.uid, this.target).then(() => {
          this.$toast.success('Updated target Scan Setting Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not update target, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const name = true;
        const target = true;
        // const targetGroup = this.target.notification_group_uid.length > 0;

        return name && target;
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
